import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch, Vue } from 'vue-property-decorator'
import { GChart } from 'vue-google-charts'
import { mapState } from 'vuex'
import moment from 'moment'
import PlatformReportsStatsInterface from '@/modules/level-one/interfaces/platform-reports-stats.interface'
import { Container } from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { PlatformReportsChartDataTypesEnum } from '@/modules/level-one/enums/platform-reports-chart-data-types.enum'
import { PlatformReportsLeaderboardsType } from '@/modules/level-one/types/platform-reports-leaderboards.type'

@Component({
  name: 'GtrRegistrationPlatformReportsView',
  components: {
    GChart
  },
  computed: {
    ...mapState('report', ['platformReportsStats', 'platformReportsChartData', 'platformReportsLeaderboards']),
    ...mapState('company', ['companies'])
  }
})
export default class GtrRegistrationPlatformReportsView extends GtrSuper {
  PlatformReportsChartDataTypesEnum: any = PlatformReportsChartDataTypesEnum
  data () {
    return {
      tab: 0,
      dateItems: [
        {
          text: 'All Time',
          value: 730
        },
        {
          text: 'Yesterday',
          value: 1
        },
        {
          text: 'Last 7 Days',
          value: 7
        },
        {
          text: 'Last 14 Days',
          value: 14
        },
        {
          text: 'Last 30 Days',
          value: 30
        },
        {
          text: 'Last 3 Months',
          value: 90
        },
        {
          text: 'Last 6 Months',
          value: 180
        },
        {
          text: 'Last 12 Months',
          value: 365
        }
      ],
      transactionDollarValueActionItems: [
        {
          text: 'All',
          value: PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal
        },
        {
          text: 'Registration',
          value: PlatformReportsChartDataTypesEnum.GlobalTransactionsRegTotal
        },
        {
          text: 'Lead Retrieval',
          value: PlatformReportsChartDataTypesEnum.GlobalTransactionsLrTotal
        }
      ],
      transactionDollarValueActionSelection: PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal,
      platformPerformanceDateSelection: 30,
      chartDateSelection: 30,
      transactionDollarValueNewOrTotal: 'New',
      platformPerformanceCards: [
        {
          title: 'New Admin User',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'Transaction Dollar Value',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'New Companies',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'New Events',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'LR App Activations',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'Track Sessions',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'LR Scans',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'Track Scans',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'Badges Printed',
          amount: 0,
          subtext: '',
          difference: 0
        },
        {
          title: 'Completed Registrations',
          amount: 0,
          subtext: '',
          difference: 0
        }
      ],
      lineChartOptions: {
        chartArea: {
          width: '85%'
        },
        pointSize: 5,
        colors: ['#53A5E4'],
        hAxis: {
          viewWindowMode: 'pretty'
        }
      },
      loadingStats: true,
      lineChartData: [['Date', ''], ['', 0]],
      loadingChart: true,
      loadingTab: true,
      loadingLeaderboards: true,
      charts: [
        {
          title: 'Transaction Dollar Value',
          type: PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal
        },
        {
          title: 'Admin Users',
          type: PlatformReportsChartDataTypesEnum.GlobalAdminUsersCreated
        },
        {
          title: 'Companies',
          type: PlatformReportsChartDataTypesEnum.GlobalCompaniesCreated
        },
        {
          title: 'Events',
          type: PlatformReportsChartDataTypesEnum.CompanyEventsTotal
        },
        {
          title: 'Registrations',
          type: PlatformReportsChartDataTypesEnum.GlobalCompletedRegistrations
        }
      ],
      leaderboards: [
        {
          title: 'Transaction Dollar Value',
          type: PlatformReportsChartDataTypesEnum.CompanyTransactionTotal
        },
        {
          title: 'Events',
          type: PlatformReportsChartDataTypesEnum.CompanyEventsTotal
        },
        {
          title: 'Participants',
          type: PlatformReportsChartDataTypesEnum.CompanyParticipantsTotal
        }
      ],
      platformReportsLeaderboardsSorted: {},
      leaderboardExportLoading: {}
    }
  }

  async mounted () {
    await Promise.all([
      this.$store.dispatch('report/getPlatformReportsStats', this.$data.platformPerformanceDateSelection),
      this.loadChart(this.$data.chartDateSelection, this.$data.transactionDollarValueActionSelection),
      this.$store.dispatch('report/getPlatformReportsLeaderboards'),
      this.$store.dispatch('company/fetchCompanies')
    ])
    this.$data.loadingStats = false
    this.$data.loadingTab = false
    this.$data.loadingLeaderboards = false
  }

  @Watch('platformReportsStats')
  onPlatformReportsStatsChange (payload: PlatformReportsStatsInterface) {
    if (Object.keys(payload).length) {
      this.updatePlatformPerformanceStats(payload)
    }
  }

  @Watch('platformReportsLeaderboards')
  onPlatformReportsLeaderboardsChange (payload: PlatformReportsLeaderboardsType) {
    if (Object.keys(payload).length) {
      for (const type in payload) {
        this.$data.platformReportsLeaderboardsSorted[type] = Object.entries(payload[type].results).sort(([, a], [, b]) => b.total - a.total).map(([key, value]) => Object.assign({}, value, { uuid: key }))
      }
    }
  }

  async handleExportPlatformReportsStats () {
    try {
      await this.$store.dispatch('report/exportPlatformReportsStats', this.$data.platformPerformanceDateSelection)
      Container.get(Notification).success('Your report has been downloaded.')
    } catch (e) {
      Container.get(Notification).error('There was a problem downloading your report. Please try again.')
    }
  }

  async handleChartDateSelectionChange ($event: number, type: PlatformReportsChartDataTypesEnum) {
    if (type === PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal) {
      type = this.$data.transactionDollarValueActionSelection
    }
    await this.loadChart($event, type)
  }

  async handleTransactionDollarValueActionSelectionChange ($event: PlatformReportsChartDataTypesEnum) {
    await this.loadChart(this.$data.chartDateSelection, $event)
  }

  async handleExportPlatformReportsChartData (type: PlatformReportsChartDataTypesEnum) {
    if (type === PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal) {
      type = this.$data.transactionDollarValueActionSelection
    }
    const spreadsheetStructure: any = {
      header_data: ['Date', type],
      row_data: []
    }
    const dataWithoutChartHeader = this.$data.lineChartData.slice(1)
    for (const row of dataWithoutChartHeader) {
      spreadsheetStructure.row_data.unshift([row[0], row[1]])
    }
    await this.$store.dispatch('report/exportPlatformReportsChartData', spreadsheetStructure)
  }

  async handleChartTabChange (type: PlatformReportsChartDataTypesEnum) {
    this.$data.loadingTab = true
    this.$data.chartDateSelection = 30
    this.$data.transactionDollarValueActionSelection = PlatformReportsChartDataTypesEnum.GlobalTransactionsTotal
    await this.loadChart(30, type)
    this.$data.loadingTab = false
  }

  async loadChart (daysBack: number, type: PlatformReportsChartDataTypesEnum) {
    this.$data.loadingChart = true
    const result = await this.$store.dispatch('report/getPlatformReportsChartData', { daysBack, type })
    const regCounts: { string: number } = result[type]
    if (!regCounts) {
      this.$data.lineChartData = [['Date', ''], ['', 0]]
    } else {
      const lineChart = [['Date', '']]
      for (const date in regCounts) {
        lineChart.push([
          moment(date).format('MM/DD/YYYY'),
          regCounts[date]
        ])
      }
      this.$data.lineChartData = lineChart
    }
    this.$data.loadingChart = false
  }

  generatePlatformPerformanceCardSubtext (daysBack: number, currentTotal: number, previousTotal: number, format: 'number' | 'currency') {
    if (daysBack === 730) {
      return ''
    }
    let moreOrFewerThanText, timeframeText, differenceText
    const difference = +currentTotal - +previousTotal

    if (format === 'currency') {
      differenceText = this.$options?.filters?.toCurrency(Math.abs(difference))
    } else {
      differenceText = this.$options?.filters?.numberWithCommas(Math.abs(difference))
    }

    if (difference > 0) {
      moreOrFewerThanText = 'more than'
    } else if (difference < 0) {
      moreOrFewerThanText = 'fewer than'
    } else {
      moreOrFewerThanText = 'the same as'
    }

    if (daysBack === 1) {
      timeframeText = 'the day before yesterday'
    } else {
      const [dateItemObject] = this.$data.dateItems.filter(item => item.value === daysBack)
      const dateItemTextValue = dateItemObject.text.replace('Last ', '').toLowerCase()
      timeframeText = `the previous ${dateItemTextValue}`
    }

    if (difference === 0) {
      differenceText = ''
    }

    return `${differenceText} ${moreOrFewerThanText} ${timeframeText}`
  }

  async handlePlatformPerformanceDateSelectionChange ($event) {
    this.$data.loadingStats = true
    await this.$store.dispatch('report/getPlatformReportsStats', $event)
    this.$data.loadingStats = false
  }

  updatePlatformPerformanceStats (payload) {
    const { current_range_data, previous_range_data } = payload
    const {
      global_admin_users_created: current_admin_users, global_companies_created: current_companies_created, global_events_created: current_events_created, global_lr_activations: current_lr_activations, global_sessions: current_global_sessions, global_lr_scans: current_lr_scans, global_track_scans: current_track_scans,
      global_badges_printed: current_badges_printed,
      global_completed_registrations: current_completed_registrations,
      global_transactions_total: current_transactions_total
    } = current_range_data
    const {
      global_admin_users_created: previous_admin_users, global_companies_created: previous_companies_created, global_events_created: previous_events_created, global_lr_activations: previous_lr_activations, global_sessions: previous_global_sessions, global_lr_scans: previous_lr_scans, global_track_scans: previous_track_scans,
      global_badges_printed: previous_badges_printed,
      global_completed_registrations: previous_completed_registrations,
      global_transactions_total: previous_transactions_total
    } = previous_range_data
    const updatedStats = [
      {
        title: 'New Admin Users',
        amount: this.$options?.filters?.numberWithCommas(current_admin_users.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_admin_users.total, previous_admin_users.total, 'number'),
        difference: current_admin_users.total - previous_admin_users.total
      },
      {
        title: 'Transaction Dollar Value',
        amount: this.$options?.filters?.toCurrency(current_transactions_total.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_transactions_total.total, previous_transactions_total.total, 'currency'),
        difference: current_transactions_total.total - previous_transactions_total.total
      },
      {
        title: 'New Companies',
        amount: this.$options?.filters?.numberWithCommas(current_companies_created.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_companies_created.total, previous_companies_created.total, 'number'),
        difference: current_companies_created.total - previous_companies_created.total
      },
      {
        title: 'New Events',
        amount: this.$options?.filters?.numberWithCommas(current_events_created.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_events_created.total, previous_events_created.total, 'number'),
        difference: current_events_created.total - previous_events_created.total
      },
      {
        title: 'LR App Activations',
        amount: this.$options?.filters?.numberWithCommas(current_lr_activations.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_lr_activations.total, previous_lr_activations.total, 'number'),
        difference: current_lr_activations.total - previous_lr_activations.total
      },
      {
        title: 'Track Sessions',
        amount: this.$options?.filters?.numberWithCommas(current_global_sessions.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_global_sessions.total, previous_global_sessions.total, 'number'),
        difference: current_global_sessions.total - previous_global_sessions.total
      },
      {
        title: 'LR Scans',
        amount: this.$options?.filters?.numberWithCommas(current_lr_scans.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_lr_scans.total, previous_lr_scans.total, 'number'),
        difference: current_lr_scans.total - previous_lr_scans.total
      },
      {
        title: 'Track Scans',
        amount: this.$options?.filters?.numberWithCommas(current_track_scans.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_track_scans.total, previous_track_scans.total, 'number'),
        difference: current_track_scans.total - previous_track_scans.total
      },
      {
        title: 'Badges Printed',
        amount: this.$options?.filters?.numberWithCommas(current_badges_printed.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_badges_printed.total, previous_badges_printed.total, 'number'),
        difference: current_badges_printed.total - previous_badges_printed.total
      },
      {
        title: 'Completed Registrations',
        amount: this.$options?.filters?.numberWithCommas(current_completed_registrations.total),
        subtext: this.generatePlatformPerformanceCardSubtext(this.$data.platformPerformanceDateSelection, current_completed_registrations.total, previous_completed_registrations.total, 'number'),
        difference: current_completed_registrations.total - previous_completed_registrations.total
      }
    ]
    this.$data.platformPerformanceCards = [...updatedStats]
  }

  formatLeaderboardTotal (type: PlatformReportsChartDataTypesEnum, total: number): string {
    if (type === PlatformReportsChartDataTypesEnum.CompanyTransactionTotal) {
      return this.$options?.filters?.toCurrency(total)
    }
    return this.$options?.filters?.numberWithCommas(total)
  }

  async exportLeaderboard (type: PlatformReportsChartDataTypesEnum) {
    try {
      Vue.set(this.$data.leaderboardExportLoading, type, true)
      await this.$store.dispatch('report/exportPlatformLeaderboard', type)
      Container.get(Notification).success('Your report has been downloaded.')
    } catch (e) {
      Container.get(Notification).error('There was a problem downloading your report. Please try again.')
    } finally {
      Vue.set(this.$data.leaderboardExportLoading, type, false)
    }
  }

  async exportPlatformLeadsSales () {
    await this.$store.dispatch('report/exportPlatformLeadsSales')
  }

  getLeaderboardLogo (leader: { company_name: string; total: number; uuid: string }): string {
    const [company] = (this as any).companies.filter(company => company.uuid === leader.uuid)
    return company.logo
  }
}
