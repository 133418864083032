import { render, staticRenderFns } from "./platform-reports.view.vue?vue&type=template&id=f412e838&scoped=true&"
import script from "./platform-reports.view.ts?vue&type=script&lang=ts&"
export * from "./platform-reports.view.ts?vue&type=script&lang=ts&"
import style0 from "./platform-reports.view.vue?vue&type=style&index=0&id=f412e838&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f412e838",
  null
  
)

export default component.exports